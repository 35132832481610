import { ReferenceFormNames } from "@src/app/professionalReferences/constants";
import {
  ErrorFields,
  Form,
  ValidationRule,
  ValidationRules,
} from "@src/lib/interface/src/lib/professionalReference";
import moment from "moment";

export const INITIAL_STATE = {
  [ReferenceFormNames.employerName]: "",
  [ReferenceFormNames.referenceFirstName]: "",
  [ReferenceFormNames.referenceLastName]: "",
  [ReferenceFormNames.employmentStartDate]: "",
  [ReferenceFormNames.employmentEndDate]: "",
  [ReferenceFormNames.workerTitle]: "",
};

export const VALIDATION_RULES: ValidationRules = {
  [ReferenceFormNames.employerName]: [
    {
      message: "Required field",
      validate: (value: string) => value.length > 0,
    },
    {
      message: "Employer name must contain more than two characters. Please enter a valid name.",
      validate: (value: string) => value.length >= 3,
    },
    {
      message:
        "We do not accept references from Clipboard. Please add a reference from a previous or current employer.",
      validate: (value: string) => {
        const lowerCaseValue = value.toLowerCase();
        return !lowerCaseValue.includes("cbh") && !lowerCaseValue.includes("clipboard");
      },
    },
  ],
  [ReferenceFormNames.workerTitle]: [
    {
      message: "Required field",
      validate: (value: string) => value.length > 0,
    },
  ],
  [ReferenceFormNames.employmentStartDate]: [
    {
      message: "Required field",
      validate: (value: string) => value.length > 0,
    },
    {
      message: "Start date cannot be in the future.",
      validate: (value: string) => moment(value).isSameOrBefore(moment()),
    },
  ],
  [ReferenceFormNames.employmentEndDate]: [
    {
      message: "Required field",
      validate: (value: string) => value.length > 0,
    },
    {
      message: "End date cannot be before start date.",
      validate: (value: string, relatedValues?: string[]) =>
        moment(value).isSameOrAfter(relatedValues?.[0]),
      relatedFields: [ReferenceFormNames.employmentStartDate],
    },
    {
      message: "End date cannot be in the future.",
      validate: (value: string) => moment(value).isSameOrBefore(moment()),
    },
  ],
  [ReferenceFormNames.referenceFirstName]: [
    {
      message: "Required field",
      validate: (value: string) => value.length > 0,
    },
    {
      message: "Please enter a valid first name.",
      validate: (value: string) => value.length >= 2,
    },
    {
      message:
        "First name cannot contain numbers or special characters. Please enter a valid first name.",
      validate: (value: string) => {
        const regex = /^[A-Za-z\s\-']+$/;
        return regex.test(value.trim());
      },
    },
  ],
  [ReferenceFormNames.referenceLastName]: [
    {
      message: "Required field",
      validate: (value: string) => value.length > 0,
    },
    {
      message: "Please enter a valid last name.",
      validate: (value: string) => value.length >= 2,
    },
    {
      message:
        "Last name cannot contain numbers or special characters. Please enter a valid last name.",
      validate: (value: string) => {
        const regex = /^[A-Za-z\s\-']+$/;
        return regex.test(value.trim());
      },
    },
  ],
};

export const getFirstOfMonthDateInIso8601Format = (params: {
  zeroIndexedMonth: number;
  fourDigitYear: number;
}): string => {
  const { zeroIndexedMonth, fourDigitYear } = params;

  // month is 0 indexed
  const monthString = `${zeroIndexedMonth + 1}`.padStart(2, "0");

  // We are only interested in the date part, so we set the time to 00:00:00.000Z
  return `${fourDigitYear}-${monthString}-01T00:00:00.000Z`;
};

export const getErrorFields = (form: Form): ErrorFields => {
  return Object.entries(form).reduce((acc: ErrorFields, [key, value]: [string, string]) => {
    const validations = VALIDATION_RULES[key];
    if (!validations) {
      return acc;
    }
    const errorsPerField = validations
      .map(({ validate, message, relatedFields }: ValidationRule) => ({
        isValid: validate(
          value,
          relatedFields?.map((key: string) => form?.[key])
        ),
        message,
      }))
      .filter(({ isValid }) => !isValid);
    return { ...acc, [key]: errorsPerField };
  }, {});
};
